<template>
   <v-container fluid>
   <v-card flat>
        <v-toolbar
        color="grey darken-4"
        dark
        extended
        flat
        >
        <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
        </v-toolbar>

        <v-card
        class="mx-auto"
        max-width="1200"
        style="margin-top: -64px;"
        :loading="loading"
        v-if="companie"
        >
        <v-toolbar flat>
            <v-toolbar-title class="black--text">Mi Comercio</v-toolbar-title>

            <v-spacer></v-spacer>
            
            <!--<v-btn v-if="companie" :to="`/me/${companie.attributes.slug ? companie.attributes.slug : companie.id}`" color="black" class="white--text">
            <v-icon class="mr-1">mdi-open-in-new</v-icon>
            Ver Perfil público
            </v-btn>-->

            <!--<v-btn icon>
            <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>-->
            <h2 v-if="companie.attributes.credits">Créditos: ${{ companie.attributes.credits.toFixed(2) }}</h2>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text>

            <v-card
                class="mx-auto mb-5"
                max-width="100%"
                outlined
            >
                <v-list-item three-line>
                <v-list-item-content>
                    <v-list-item-title class="headline mb-3">{{ companie.attributes.name }} </v-list-item-title>
                    <v-list-item-subtitle style="display: block;" v-if="credential && !seeApiKey" class="pb-2"> <v-icon>mdi-key</v-icon> <strong>API KEY:</strong> {{ credential.attributes.apiKey.slice(0, 15) }}... <v-icon @click="seeApiKey = true">mdi-eye</v-icon> </v-list-item-subtitle>
                    <v-list-item-subtitle style="display: block;" v-else-if="credential" class="pb-2"> <v-icon>mdi-key</v-icon> <strong>API KEY:</strong> {{ credential.attributes.apiKey }} <v-icon @click="seeApiKey = false">mdi-eye-off</v-icon> </v-list-item-subtitle>
                    <v-list-item-subtitle style="display: block;" v-if="credential && !seeSecretKey" class="pb-2"> <v-icon>mdi-key</v-icon> <strong>SECRET KEY:</strong> {{ credential.attributes.secretKey.slice(0, 15) }}... <v-icon @click="seeSecretKey = true">mdi-eye</v-icon> </v-list-item-subtitle>
                    <v-list-item-subtitle style="display: block;" v-else-if="credential" class="pb-2"> <v-icon>mdi-key</v-icon> <strong>SECRET KEY:</strong> {{ credential.attributes.secretKey}} <v-icon @click="seeSecretKey = false">mdi-eye-off</v-icon> </v-list-item-subtitle>
                    <v-list-item-subtitle style="display: block;" class="pb-2"> <v-icon>mdi-phone</v-icon> <strong>TELÉFONO:</strong> {{ companie.attributes.phone }} </v-list-item-subtitle>
                    <v-list-item-subtitle style="display: block;" class="pb-2"> <v-icon>mdi-map-marker</v-icon> <strong>DIRECCIÓN:</strong> {{ companie.attributes.address }} </v-list-item-subtitle>
                    <v-list-item-subtitle style="display: block;" v-if="companie.attributes.country" class="pb-2"> <v-icon>mdi-flag</v-icon> <strong>PAÍS:</strong> {{ companie.attributes.country }} </v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                
                </v-card-actions>
            </v-card>

            <v-data-table
                loading-text="Cargando... Por favor espere"
                :headers="headers"
                :items="transacciones"
                :search="search"
                :header-props="{ sortIcon: null }"
                class="elevation-1"
            >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>CAMPAÑAS</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <download-excel
                      class = "mb-2 d-none d-sm-flex v-btn v-btn--contained theme--dark v-size--small black mr-1"
                      :data = "transacciones"
                      :fields = "headers_export"
                      worksheet = "FALCON"
                      name = "transaction_falcon.xls">
                          <v-icon left style="color:white;">mdi-microsoft-excel</v-icon> Descargar en Excel  
                    </download-excel>
                    <v-btn color="black" dark class="mb-2 mr-1 d-none d-sm-flex" @click="modalRecharge = true" small> <v-icon left>mdi-cash-usd-outline</v-icon> Recargar cuenta </v-btn>
                    <v-btn color="black" dark class="mb-2 d-none d-sm-flex" @click="$store.state.modalSendSmsCall = true" small> <v-icon left>mdi-phone-message</v-icon> Enviar SMS / Llamada </v-btn>
                    <v-btn icon @click="dialogFilterTransaction = true" class="mb-2 mr-1">
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>
                </v-toolbar>
                </template>

                <template v-slot:item.attributes.createdAt="{ item }">
                    {{$moment(item.attributes.createdAt).format('lll')}}
                </template>

                <template v-slot:item.attributes.message="{ item }">
                   {{ item.attributes.message.length >= 80 ? item.attributes.message.slice(0, 80)+'...' : item.attributes.message}}
                </template>

                <template v-slot:item.attributes.destinations="{ item }">
                    {{ item.attributes.destinations.length > 0 ? item.attributes.destinations.length : 0 }}
                </template>

                 <template v-slot:item.attributes.kind="{ item }">
                   <v-icon v-if="item.attributes.kind == 'call'">mdi-phone</v-icon>
                   <v-icon v-else-if="item.attributes.kind == '2FA'">mdi-shield-check</v-icon>
                   <v-icon v-else>mdi-message-processing</v-icon>
                </template>

                <!--<template v-slot:item.attributes.cost="{ item }">
                   {{ item.attributes.cost | currency }}
                </template>-->

                <template v-slot:item.action="{ item }">
                    <v-icon
                        class="mr-2"
                        @click="showDialogTransaction(item)"
                    >
                        mdi-eye-outline
                    </v-icon>

                    <v-icon
                        v-if="item.attributes.kind == 'sms'"
                        class="mr-2"
                        @click="goMessage(item)"
                    >
                        mdi-file-chart
                    </v-icon>
                </template>

                <template v-slot:no-data>
                <v-btn color="primary" @click="init()">RECARGAR</v-btn>
                </template>
            </v-data-table>
           
        </v-card-text>
        </v-card>
    </v-card>

    <!-- Dialogo Dialogo recarga -->
    <v-dialog
      v-model="modalRecharge"
      width="700"
      scrollable
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          <span class="text-capitalize"> Recargar <strong v-if="amount && amount == 50">(Pack 50 USD)</strong> <strong v-else-if="amount && amount == 100">(Pack 100 USD)</strong> <strong v-else-if="amount && amount == 150">(Pack 150 USD)</strong>  <strong v-else-if="amount && amount == 500">(Pack 500 USD)</strong> </span>
        </v-card-title>

        <v-card-text>
          <div class="mb-2"></div>
          <v-row align="center" justify="center">

             <v-col
              cols="12"
              class="body-2"
            >
              Selecciona uno de nuestros paquetes a continuación:
             </v-col>

            <v-col
              cols="12"
              sm="6"
              class="ma-0 pa-0 pb-2 pr-1"
            >
              <v-card
                class="ma-0 pa-0"
                max-width="400"
                outlined
                color="info"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1 white--text">
                      Paquete $50 USD
                    </v-list-item-title>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor SMS: $0.0049</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor Llamada: $0.033</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Total: 10,204 SMS</v-list-item-subtitle>
                  </v-list-item-content>

                
                </v-list-item>

                <v-card-actions class="pt-0 mt-0">
                  <v-btn
                    outlined
                    rounded
                    text
                    small
                    class="white--text"
                    @click="amount = 50"
                  >
                    Seleccionar Paquete
                  </v-btn>
                  <v-icon v-if="amount == 50" class="white--text">mdi-check</v-icon>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="ma-0 pa-0 pb-2 pr-1"
            >
              <v-card
                class="ma-0 pa-0"
                max-width="400"
                outlined
                color="orange"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1 white--text">
                      Paquete $100 USD
                    </v-list-item-title>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor SMS: $0.0039</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor Llamada: $0.033</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Total: 25,641 SMS</v-list-item-subtitle>
                  </v-list-item-content>

                
                </v-list-item>

                <v-card-actions class="pt-0 mt-0">
                  <v-btn
                    outlined
                    rounded
                    text
                    small
                    class="white--text"
                    @click="amount = 100"
                  >
                    Seleccionar Paquete
                  </v-btn>
                  <v-icon v-if="amount == 100" class="white--text">mdi-check</v-icon>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="ma-0 pa-0 pb-2 pr-1"
            >
              <v-card
                class="ma-0 pa-0"
                max-width="400"
                outlined
                color="success"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1 white--text">
                      Paquete $150 USD
                    </v-list-item-title>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor SMS: $0.0039</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor Llamada: $0.030</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Total: 38,461 SMS</v-list-item-subtitle>
                  </v-list-item-content>

                
                </v-list-item>

                <v-card-actions class="pt-0 mt-0">
                  <v-btn
                    outlined
                    rounded
                    text
                    small
                    class="white--text"
                    @click="amount = 150"
                  >
                    Seleccionar Paquete
                  </v-btn>
                  <v-icon v-if="amount == 150" class="white--text">mdi-check</v-icon>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="ma-0 pa-0 pb-2"
            >
              <v-card
                class="ma-0 pa-0"
                max-width="400"
                outlined
                color="purple"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1 white--text">
                      Paquete $500 USD
                    </v-list-item-title>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor SMS: $0.0.0035</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor Llamada: $0.030</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Total: 144,928 SMS</v-list-item-subtitle>
                  </v-list-item-content>

                
                </v-list-item>

                <v-card-actions class="pt-0 mt-0">
                  <v-btn
                    outlined
                    rounded
                    text
                    small
                    class="white--text"
                    @click="amount = 500"
                  >
                    Seleccionar Paquete
                  </v-btn>
                  <v-icon v-if="amount == 500" class="white--text">mdi-check</v-icon>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="12" class="caption">
              Estos planes incluyen los costos de sms y llamadas válidos para Colombia, los costos a otros países varían según la referencia. Para más información, comunícate con nosotros al email: contacto@falconsignal.co
            </v-col>
           
          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="modalRecharge = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            outlined
            @click="rechargePay()"
          >
           <v-icon left>mdi-send</v-icon> Pagar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo recarga -->

    <!-- Dialogo detalle transaction -->
    <v-dialog
      v-model="modalTrandaction"
      width="500"
      scrollable
      v-if="transaccion"
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          <span class="text-capitalize"> Detalle transacción </span>
        </v-card-title>

        <v-card-text>
          <div class="mb-2"></div>
          <v-row align="center" justify="center">

            <v-col cols="12">
                <div class="body-1 text-wrap mb-3" style="color: black;" v-if="transaccion.attributes.label"> <strong><v-icon>mdi-tag</v-icon> Etiqueta:</strong> {{ transaccion.attributes.label }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;"> <strong><v-icon>mdi-calendar</v-icon> Fecha:</strong> {{ $moment(transaccion.attributes.createdAt).format('lll') }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;"> <strong><v-icon>mdi-phone-message</v-icon> Tipo:</strong> {{ transaccion.attributes.kind }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;"> <strong><v-icon>mdi-message</v-icon> Mensaje:</strong> {{ transaccion.attributes.message }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;"> <strong><v-icon>mdi-account-group</v-icon> No. Destinatarios:</strong> {{ transaccion.attributes.destinations.length }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;"> <strong><v-icon>mdi-cellphone</v-icon> Destinatarios:</strong> {{ transaccion.attributes.destinations.join(', ') }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;"> <strong><v-icon>mdi-cash</v-icon> Costo:</strong> ${{ transaccion.attributes.cost ? Number.parseFloat(transaccion.attributes.cost).toFixed(5) : '-' }} </div>
            </v-col>
           
          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="modalTrandaction = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo detalle transaction -->

    <!-- Dialogo filter orders -->
    <v-dialog v-model="dialogFilterTransaction" width="700" persistent scrollable>
      <v-form ref="formFilter" v-model="validFilter">
        <v-card>
          <v-card-title class="title grey lighten-2" primary-title>
            <span class="text-capitalize">Filtrar Transacciones</span>
          </v-card-title>

          <v-card-text>
            <div class="mb-2"></div>
            <v-row>
              <v-col cols="12" sm="6">
                <v-date-picker v-model="dates" range @change="checkDates()" locale="es"></v-date-picker>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="dateRangeText" label="Rango de fechas (UTC-5)" prepend-inner-icon="mdi-calendar" readonly outlined hide-details></v-text-field>
                <h3
                  ref="radio"
                  class="mt-3"
                >
                  Tipo de transacciones
                </h3>
                <v-radio-group v-model="sourceOption" class="mt-1">
                  <v-radio
                    label="Todos"
                    value="all"
                  ></v-radio>
                  <v-radio
                    label="SMS"
                    value="sms"
                  ></v-radio>
                  <v-radio
                    label="CALL (Llamadas)"
                    value="call"
                  ></v-radio>
                </v-radio-group>
                
                
                 <v-text-field
                    v-model="labelText"
                    label="Texto en etiqueta"
                    hint="Por ejemplo el nombre de un comercio o el nombre de una campaña"
                    outlined
                    class="mt-3"
                  ></v-text-field>

              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="red" outlined @click="dialogFilterTransaction = false">
              <v-icon left>mdi-close</v-icon>Cerrar
            </v-btn>
            <v-spacer></v-spacer>
            
            <v-btn color="primary" dark outlined @click="init()">
              <v-icon left>mdi-autorenew</v-icon>Limpiar
            </v-btn>
            <v-btn color="success" dark outlined @click="filterTransaction()">
              <v-icon left>mdi-filter</v-icon>Filtrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- *End* Dialogo filter orders -->

   </v-container>
</template>

<script>
import Parse from 'parse'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import JsonExcel from 'vue-json-excel'

const isPhone = (value) => /^3[0-3]\d{8}$/.test(value); 

export default {

    components:{
      'download-excel': JsonExcel,
    },

    mixins: [validationMixin],

    validations: {
      phone: {
        required,
        phoneValid: isPhone
      },
    },

    data() {
        return {
            companie: '',
            credential: '',
            transacciones: [],
            transaccion: '',
            loading: false,
            headers: [
              //{ text: 'Comercio', align: 'left', value: 'attributes.store' },
              //{ text: 'ID', align: 'left', value: 'id' },
              { text: 'Fecha creación', align: 'left', value: 'attributes.createdAt' },
              { text: 'Tipo',align: 'left',  value: 'attributes.kind' },
              { text: 'Mensaje', align: 'left', value: 'attributes.message' },
              { text: 'Destinatarios', align: 'center', value: 'attributes.destinations' },
              //{ text: 'Costo',align: 'left',  value: 'attributes.cost' },
              { text: 'Etiqueta', align: 'left', value: 'attributes.label' },
              { text: 'Acciones', align: 'center', value: 'action', sortable: false },
            ],
            search: '',
            seeApiKey: false,
            seeSecretKey: false,

            phoneRules: [
                v => !!v || 'Título es requerido'
            ],
            bodyRules: [
                v => !!v || 'Mensaje es requerido',
                v => !!v && v.length <= 160 || 'El mensaje no debe sobrepasar los 160 caracteres'
            ],
            modalTrandaction: false,
            amount: '',
            modalRecharge: false,
            dialogFilterTransaction: false,
            validFilter: false,
            dates: [],
            sourceOption: 'all',
            labelText: '',
            headers_export: {
              'ID': 'id',
              'Tipo': 'attributes.kind',
              'Mensaje': 'attributes.message',
              'Destinatarios': 'attributes.destinations',
              'Cantidad': {
                  field: 'attributes.destinations',
                  callback: (value) => {
                      return value ? value.length : 0;
                  }
              },
              'Etiqueta': 'attributes.label',
              'Fecha creación': {
                  field: 'attributes.createdAt',
                  callback: (value) => {
                      let startDate = value.toLocaleString()
                      return `${startDate}`;
                  }
              }
            },
        }
    },

    mounted() {
        this.$store.state.menuAdminActive = true
        this.init()
    },

    computed: {
        phoneErrors () {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.phoneValid && errors.push('El celular no es valido.')
            !this.$v.phone.required && errors.push('Celular es requerido.')
            return errors
        },

        dateRangeText () {
          return this.dates.join(' ~ ')
        },
    },

    methods: {
        
        async init(){
            this.loading = true
            try {
                let params = {
                    id: Parse.User.current().id
                }

                let companie = await Parse.Cloud.run("getCompany", params)
                this.companie = companie
                //console.log(companie.id);
                
                this.getApiCredentials(companie.id)
                this.getTransaction(companie.id)

            } catch (error) {
                this.$snotify.error(error.message , 'Empresa', {
                    timeout: 4000,
                    preventDuplicates: true
                 }); 
            }
        },

        async getApiCredentials(id){
            try {
                let params = {
                    id: id
                }

                let credential = await Parse.Cloud.run("getApicredential", params)
                this.credential = credential
                //console.log(credential);
                this.loading = false

            } catch (error) {
                this.$snotify.error(error.message , 'Credenciales', {
                    timeout: 4000,
                    preventDuplicates: true
                 }); 
            }
        },

        async getTransaction(id){
            try {
                let params = {
                    id: id
                }

                let transacciones = await Parse.Cloud.run("getTransaction", params)
                this.transacciones = transacciones
                //console.log(transacciones);
                this.loading = false
                this.dialogFilterTransaction = false

            } catch (error) {
                this.$snotify.error(error.message , 'Transacciones', {
                    timeout: 4000,
                    preventDuplicates: true
                 }); 
            }
        },
        rechargePay(){
            if (!this.amount || this.amount < 50) {
                this.$snotify.error(`Monto de recarga muy bajo` , 'Recargas', {
                    timeout: 4000,
                    preventDuplicates: true
                });
                return;
            }

            //eslint-disable-next-line
            var handler = ePayco.checkout.configure({
                key: '36dcadb137adf6eea8c56e63cd254b27',
                test: false
            })

            var data = {
                //Parametros compra (obligatorio)
                name: `Recarga Falcon por ${this.amount}`,
                description: `Recarga falcon del cliente ${this.companie.id} - ${this.companie.attributes.name} por valor de ${this.amount} de la ciudad de ${this.companie.attributes.city}`,
                currency: "usd",
                amount: this.amount,
                tax_base: "0",
                tax: "0",
                country: "co",
                lang: "es",

                //Onpage="false" - Standard="true"
                external: "false",

                //Atributos opcionales
                extra1: this.companie.id,
                extra2: this.companie.attributes.name,
                extra3: this.companie.attributes.owner.id,
                confirmation: process.env.VUE_APP_URL_CONFIRMATION_PAYCO,
                response: process.env.VUE_APP_URL_RESPONSE_PAYCO,

                //Atributos cliente
                /*name_billing: "Andres Perez",
                address_billing: "Carrera 19 numero 14 91",
                type_doc_billing: "cc",
                mobilephone_billing: "3050000000",
                number_doc_billing: "100000000",*/

                //atributo deshabilitación metodo de pago
                //methodsDisable: ["TDC", "PSE","SP","CASH","DP"]
            }

            //eslint-disable-next-line
            //console.log(data);

            handler.open(data)
        },

        showDialogTransaction(item){
            this.transaccion = item
            this.modalTrandaction = true
        },

        async filterTransaction(){

          if (!this.dates || this.dates.length <= 1) {
            this.$snotify.error(`Debe seleccionar un rango de fechas válido`, "Fechas", {
              timeout: 4000,
              preventDuplicates: true
            });
            return;
          }

          const params = {
            id:  this.companie.id,
            init: this.dates[0],
            end: this.dates[1],
            kind: this.sourceOption,
            label: this.labelText,
          };

          try {
            let transacciones = await Parse.Cloud.run("getTransactionFilterBO", params);
            this.transacciones = transacciones
            //eslint-disable-next-line
            //console.log(this.invoices)
            this.dialogFilterTransaction = false

          } catch (error) {
            this.$snotify.error(`${error}`, "Transacciones filtro", {
              timeout: 4000,
              preventDuplicates: true
            });
          }

        },

        checkDates(){
          this.dates.sort(function(a, b) {
              var c = new Date(a);
              var d = new Date(b);
              return c-d;
          });
        },

        goMessage(item){
          let routeData = this.$router.resolve('/messages/' + item.id);
          window.open(routeData.href, '_blank');
        }

    },
}
</script>